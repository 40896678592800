<template>
  <h1>NestOneOne</h1>
</template>

<script>
export default {
name: "NestOneOne"
}
</script>

<style scoped>

</style>